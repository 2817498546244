@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  margin: 0;
  /* background-color: #000; */
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.message-list {
  height: 100%;
}

.thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 6px;  
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #000;
  padding: 2px;
}

.carousel-status {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filter-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.image-gradient-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  z-index: 10;
}

.clip-top-tilt {
  clip-path: polygon(0 0, 100% 0, 100% 40%, 0 60%);
}

.clip-bottom-tilt {
  clip-path: polygon(0 60%, 100% 30%, 100% 100%, 0 100%);
}

